// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    auth0: {
        clientID: 'ACosQ3hv5Po7oIEPr8GDed4peFArLvHQ',
        domain: 'dev-auth.sprintray.com',
        audience: 'http://sprintray-design-lab-assistant-dev',
        scope: 'openid email profile',
        responseType: 'token id_token',
    },
    appAccountUrl: 'https://dev.account.sprintray.com',
    designServiceUrl: 'https://dev.designservice.sprintray.com',
    threedViewerUrl: 'https://3dviewer.dev.designservice.sprintray.com',
    bridgerAppUrl: 'http://localhost:53292',
    organizations: {
        aspen: {
            id: 'd2c90157-d6e0-44f0-bef7-d90868c68389',
        },
        sprintRay: {
            id: '41ecba25-a816-436a-87c6-8959abb48eee',
        },
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
