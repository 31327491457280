import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TreatmentDemoVideoAssignInputModel } from './models/treatment-demo-video-assign-input-model';
import { TreatmentDemoVideoMetadata } from '../model';

const basePath = `/api/treatment-demo-videos`;

@Injectable({
    providedIn: 'root',
})
export class TreatmentDemoVideoFlowClient {
    private readonly _http = inject(HttpClient);

    assignDemoVideoEditor(inputModel: TreatmentDemoVideoAssignInputModel) {
        return this._http.put<TreatmentDemoVideoMetadata>(`${basePath}:assign-editor`, inputModel);
    }

    removeDemoVideoFile(demoVideoMetadataId: number, treatmentFileId: number) {
        return this._http.delete(`${basePath}/${demoVideoMetadataId}/files/${treatmentFileId}`);
    }
}
