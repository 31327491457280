import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const basePath = '/api';

@Injectable({
    providedIn: 'root',
})
export class DesignFileUploadClient {
    private readonly _http = inject(HttpClient);

    upload(treatmentId: string, designId: number, body: FormData) {
        return this._http.post(`${basePath}/treatments/${treatmentId}/designs/${designId}/files`, body, {
            observe: 'response',
            reportProgress: true,
        });
    }
}
