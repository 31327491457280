import { TreatmentSubType } from './treatmentSubType';

export interface TreatmentType {
    id: number;
    name: string;
    isAi: boolean;
    originalId: number;

    subTypes?: TreatmentSubType[];
}

export type TreatmentTypes = TreatmentType[];

export enum TreatmentTypeOriginalId {
    NightGuard = 1,
    SmileDesign = 2,
    BracketRemoval = 3,
    SurgicalGuide = 4,
    RemovablePartialDenture = 5,
    ClearAligner = 6,
    SingleImplantWithCrown = 7,
    CrownAndBridge = 8,
    CustomBleachingTray = 9,
    RemovableDentures = 10,
    AINightGuard = 11,
    HybridDentures = 13,
    AICrown = 14,
    SmileCorrect = 15,
    Veneers = 16,
    DentalModel = 19,
    ImplantRestoration = 20,
    ManualRetainer = 21,
    Custom = 100,
    NotSelected = 101,
}
